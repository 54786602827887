<template>
  <div class="white--text pt-16 mt-12" v-if="project != null">
    <v-row
      v-if="loading == false"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''"
    >
      <v-col class="fill-height px-8" cols="12" md="6" xl="7">
        <v-row>
          <v-col
            v-for="(image, i) in project.images.gallery"
            :key="image.i"
            cols="12"
            sm="6"
            md="12"
            lg="6"
            xl="4"
            class="d-flex flex-wrap justify-center"
          >
            <v-img
              @click="gallery = true"
              v-if="i < 5"
              :src="image.temp"
              min-height="300"
            />
            <v-img
              @click="gallery = true"
              v-if="i == 5"
              :src="image.temp"
              min-height="300"
            >
              <v-overlay absolute :opacity="0.6">
                <p class="display-1">
                  {{ project.images.gallery.length - 5 }} +
                </p>
              </v-overlay>
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="fill-height px-10" cols="12" md="6" xl="5">
        <div class="d-flex justify-space-between">
          <h2>PROJECT DETAILS</h2>
          <v-btn
            v-if="isAuthenticated"
            outlined
            rounded
            color="primary"
            :to="`/project-edit/${$route.params.id}`"
          >
            <v-icon left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </div>
        <h2 class="display-2">{{ project.name }}</h2>
        <h3 class="font-weight-light pt-2 pr-16">{{ project.description }}</h3>
        <v-divider class="my-3" dark />
        <div class="d-flex">
          <h3 class="font-weight-bold pt-2" v-if="project.dateCompleted">
            COMPLETION DATE:
          </h3>
          <h3 class="font-weight-light pt-2 ml-4" v-if="project.dateCompleted">
            {{ project.dateCompleted }}
          </h3>
        </div>
        <div class="d-flex">
          <h3 class="font-weight-bold pt-2" v-if="project.client">CLIENT:</h3>
          <h3 class="font-weight-light pt-2 ml-4" v-if="project.client">
            {{ project.client }}
          </h3>
        </div>
        <div class="d-flex">
          <h3 class="font-weight-bold pt-2" v-if="project.location">
            LOCATION:
          </h3>
          <h3 class="font-weight-light pt-2 ml-4" v-if="project.location">
            {{ project.location }}
          </h3>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="py-10 text-center"
    >
    <v-col>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      />
    </v-col>
      <!-- <v-col class="fill-height" cols="12" md="6" xl="7">
        <v-row>
          <v-col
            v-for="image in 6"
            :key="image.i"
            cols="12"
            sm="6"
            md="12"
            lg="6"
            xl="4"
            class="d-flex flex-wrap justify-center px-10"
          >
            <v-sheet color="transparent" class="pa-3">
              <v-skeleton-loader
                class="mx-auto"
                boilerplate
                min-width="300"
                type="image"
              ></v-skeleton-loader>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="fill-height px-10" cols="12" md="6" xl="5">
        <div class="d-flex justify-space-between">
          <h2>PROJECT DETAILS</h2>
          <v-btn
            v-if="isAuthenticated"
            outlined
            rounded
            color="primary"
            :to="`/project-edit/${$route.params.id}`"
          >
            <v-icon left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </div>
        <h2 class="display-2">{{ project.name }}</h2>
        <h3 class="font-weight-light pt-2 pr-16">{{ project.description }}</h3>
        <v-divider class="my-3" dark />
        <h3 class="font-weight-light pt-2">COMPLETION DATE</h3>
        <h3 class="font-weight-light pt-2">{{ project.dateCompleted }}</h3>
        <h3 class="font-weight-light pt-2">CLIENT</h3>
        <h3 class="font-weight-light pt-2">{{ project.client }}</h3>
        <h3 class="font-weight-light pt-2">LOCATION</h3>
        <h3 class="font-weight-light pt-2">{{ project.location }}</h3>
      </v-col> -->
    </v-row>

    <v-dialog v-model="gallery" width="90vw">
      <v-card color="secondary" height="90vh">
        <v-btn
          id="closeDialog"
          color="white"
          text
          absolute
          top
          right
          @click="gallery = false"
        >
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
        <vueper-slides
          ref="vueperslides1"
          :touchable="false"
          fade
          :autoplay="false"
          :bullets="false"
          @slide="
            $refs.vueperslides2.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
          fixed-height="80vh"
        >
          <vueper-slide
            v-for="(slide, i) in project.images.gallery"
            :key="i"
            :image="slide.temp"
          >
          </vueper-slide>
        </vueper-slides>

        <vueper-slides
          class="no-shadow thumbnails"
          ref="vueperslides2"
          @slide="
            $refs.vueperslides1.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
          :visible-slides="project.images.gallery.length"
          fixed-height="80px"
          :bullets="false"
          :touchable="false"
          :gap="2.5"
          :arrows="false"
        >
          <vueper-slide
            v-for="(slide, i) in project.images.gallery"
            :key="i"
            :image="slide.temp"
            @click.native="$refs.vueperslides2.goToSlide(i)"
          >
          </vueper-slide>
        </vueper-slides>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { storage, projectsCollection } from "../firebase/credentials";
// import Hero from "@/components/Page/Hero";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState } from "vuex";
export default {
  components: {
    // Hero,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      gallery: false,
      loading: true,
      project: {
        id: null,
        name: "",
        dateCompleted: null,
        images: {
          featured: {
            file: null,
            path: null,
            temp: null,
          },
          gallery: [],
        },
      },
      hero: {
        title: "Let's build a better",
        titleColored: "Dirt world",
        subtitle:
          "Irure in sunt exercitation sit nulla tempor ex in magna pariatur mollit in.",
      },
    };
  },
  computed: {
    ...mapState(["isAuthenticated"]),
  },
  async created() {
    if (this.$route.params.id) {
      let result = await projectsCollection.doc(this.$route.params.id);
      result.onSnapshot(async (a) => {
        let images = a.data().images;
        let gallery = [];
        for (let i = 0; i < images.length; i++) {
          gallery.push({
            path: images[i],
            temp: await storage.ref().child(images[i]).getDownloadURL(),
          });
        }
        this.project = await {
          name: a.data().name,
          description: a.data().description,
          dateCompleted: a.data().dateCompleted,
          client: a.data().client,
          location: a.data().location,
          images: {
            featured: {
              path: a.data().featured,
              temp: await storage
                .ref()
                .child(a.data().featured)
                .getDownloadURL(),
            },
            gallery: gallery,
          },
        };
        this.project.images.gallery.unshift(this.project.images.featured);
        this.loading = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#closeDialog {
  z-index: 200;
}
.vueperslide {
  background-size: contain;
}

.thumbnails {
  margin: auto;
  max-width: 100%;
}

.thumbnails .vueperslide {
  // box-sizing: border-box;
  // border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: var(--v-primary-base);
}

::v-deep .v-responsive__content {
  cursor: pointer;
}
</style>
